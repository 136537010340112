











































































































import { Component, PropSync, Ref, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { VForm } from "@/types/vForm";
import Validation from "@/mixins/validation";
import Api from "@/api";
import SnackbarModule from "@/store/snackbarModule";
import { AgencyUpsertModel } from "@/api/generated";
import CountryAutocomplete from "@/components/common/country/CountryAutocomplete.vue";
import UserModule from "@/store/userModule";

const snackbarModule = getModule(SnackbarModule);
const userModule = getModule(UserModule);

@Component({
  components: {
    CountryAutocomplete
  }
})
export default class AgencyEdit extends Validation {
  @Ref() private form!: VForm;
  @Prop(String) agencyId!: string;

  private loading = false;
  private model: AgencyUpsertModel = {
    name: "",
    countryId: "",
    licenceNumber: "",
    postalAddress: "",
    companyRegistrationNumber: ""
  };

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  private async created() {
    const response = await Api.AgencyService.apiAgencyAgencyIdGet(
      this.agencyId
    );

    this.model = response.data;
  }

  private async onAgencyUpdate() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;
      await Api.AgencyService.apiAgencyAgencyIdPut(this.agencyId, this.model);
      snackbarModule.setSnackbarMessage("Agency updated");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to update agency");
    } finally {
      this.loading = false;
    }
  }
}
